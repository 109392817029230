/**
 * * Titolo
 * ! Alert
 * ? Descrizione
 * TODO: REMINDER
 * @parametro
 */

import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout isIndex={true} bgImg="home.jpg">
    <Seo title="Home" />
  </Layout>
)

export default IndexPage